import { render } from "./VideoPlayer.vue?vue&type=template&id=40e8658c&bindings={\"srcHd\":\"props\",\"poster\":\"props\",\"name\":\"props\",\"videoDuration\":\"props\",\"markComplete\":\"props\",\"userId\":\"props\",\"lessonId\":\"props\",\"startTimestamp\":\"props\",\"markCompleteUrl\":\"props\"}"
import script from "./VideoPlayer.vue?vue&type=script&lang=js"
export * from "./VideoPlayer.vue?vue&type=script&lang=js"

import "./VideoPlayer.vue?vue&type=style&index=0&lang=css"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "40e8658c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('40e8658c', script)) {
    api.reload('40e8658c', script)
  }
  
  module.hot.accept("./VideoPlayer.vue?vue&type=template&id=40e8658c&bindings={\"srcHd\":\"props\",\"poster\":\"props\",\"name\":\"props\",\"videoDuration\":\"props\",\"markComplete\":\"props\",\"userId\":\"props\",\"lessonId\":\"props\",\"startTimestamp\":\"props\",\"markCompleteUrl\":\"props\"}", () => {
    api.rerender('40e8658c', render)
  })

}

script.__file = "web/vue/VideoPlayer.vue"

export default script