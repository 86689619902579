<template>
    <div class="hero-review">
        <span>{{ headline }}:</span>
        <div class="chart easypiechart" v-bind:data-percent="statCounter" data-scale-color="#e3e3e3"><span>{{statCounter}}</span>%</div>
    </div>
</template>


<script>
export default {
    name: 'CourseCompleteCounter',
    props: {
        headline: String
    },
    data: function(){
        return {
            statCounter : 0
        }
    },
    computed: {
        courseCount(){
            return this.$store.state.course.count;
        },
        courseComplete(){
            return this.$store.state.course.complete;
        }
    },
    mounted: function(){

        if( this.courseCount === 0 ){
            this.statCounter = 0;
        }else{
            this.statCounter = (this.courseComplete / this.courseCount) * 100;
        }
    },

    methods:{
        getPercent(){
            if( this.courseCount === 0 ){
                this.statCounter = 0;
            }else{
                this.statCounter = (this.courseComplete / this.courseCount) * 100;
            }
            if( typeof $('.chart').data('easyPieChart') !== "undefined" ){
                $( '.chart' ).data( 'easyPieChart' ).update( this.statCounter );
            }
        }
    },
    watch:{
        courseCount: function(){
            this.getPercent();
        },
        courseComplete(){
            this.getPercent();
        }
    }
}
</script>

<style>
</style>