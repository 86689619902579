import { render } from "./CourseCompleteCounter.vue?vue&type=template&id=1cddd566&bindings={\"headline\":\"props\",\"courseCount\":\"options\",\"courseComplete\":\"options\",\"getPercent\":\"options\"}"
import script from "./CourseCompleteCounter.vue?vue&type=script&lang=js"
export * from "./CourseCompleteCounter.vue?vue&type=script&lang=js"

import "./CourseCompleteCounter.vue?vue&type=style&index=0&lang=css"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "1cddd566"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1cddd566', script)) {
    api.reload('1cddd566', script)
  }
  
  module.hot.accept("./CourseCompleteCounter.vue?vue&type=template&id=1cddd566&bindings={\"headline\":\"props\",\"courseCount\":\"options\",\"courseComplete\":\"options\",\"getPercent\":\"options\"}", () => {
    api.rerender('1cddd566', render)
  })

}

script.__file = "web/vue/CourseCompleteCounter.vue"

export default script