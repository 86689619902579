import script from "./AjaxInclude.vue?vue&type=script&lang=js"
export * from "./AjaxInclude.vue?vue&type=script&lang=js"

import "./AjaxInclude.vue?vue&type=style&index=0&lang=css"
/* hot reload */
if (module.hot) {
  script.__hmrId = "19f8da66"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('19f8da66', script)) {
    api.reload('19f8da66', script)
  }
  
}

script.__file = "web/vue/AjaxInclude.vue"

export default script