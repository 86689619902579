import { createApp } from 'vue';
import { createStore } from 'vuex';
import App from "../../vue/App";

export const vueStates = createStore({
    state(){
        return {
            // When Video in VideoLesson is Complete, this value is changed
            videoLessonComplete: false,

            // Wenn Lesson auf detailseite complete
            detailLessonComplete: false,

            // On Course Detail page the Complete percent is calculated here
            course: {
                count: 0,
                complete: 0
            }
        };
    }
})

const vueApp = createApp(App);
vueApp.use(vueStates);
vueApp.mount('#vApp');