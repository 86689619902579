import { render } from "./CourseProgress.vue?vue&type=template&id=3fa2b2da&bindings={\"courseId\":\"props\",\"lessonId\":\"props\",\"timeoutSeconds\":\"props\",\"updateUrl\":\"props\",\"timer\":\"data\",\"updateCourse\":\"options\",\"sendUpdateXhr\":\"options\"}"
import script from "./CourseProgress.vue?vue&type=script&lang=js"
export * from "./CourseProgress.vue?vue&type=script&lang=js"

import "./CourseProgress.vue?vue&type=style&index=0&lang=css"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "3fa2b2da"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('3fa2b2da', script)) {
    api.reload('3fa2b2da', script)
  }
  
  module.hot.accept("./CourseProgress.vue?vue&type=template&id=3fa2b2da&bindings={\"courseId\":\"props\",\"lessonId\":\"props\",\"timeoutSeconds\":\"props\",\"updateUrl\":\"props\",\"timer\":\"data\",\"updateCourse\":\"options\",\"sendUpdateXhr\":\"options\"}", () => {
    api.rerender('3fa2b2da', render)
  })

}

script.__file = "web/vue/CourseProgress.vue"

export default script