import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"
/* hot reload */
if (module.hot) {
  script.__hmrId = "5ea8ff9f"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5ea8ff9f', script)) {
    api.reload('5ea8ff9f', script)
  }
  
}

script.__file = "web/vue/App.vue"

export default script