<template>
    <div id="video" class="video-play video-js-container">
        <div class="embed-responsive embed-responsive-16by9">
            <div class="video-caption">
                <h5 v-if="name != ''">{{ name }}</h5>
                <span class="length">{{ videoDuration }}</span>
            </div>
            <div class="video-play-icon">
                <video id="player" class="video-js"></video>
            </div>
        </div>
    </div>
</template>


<script>

import { reactive } from 'vue';

export default {
    name: 'VideoPlayer',
    props: {
        srcHd: String,
        poster: String,
        name: String,
        videoDuration: String,
        markComplete: {
            type: Number,
            default: 20
        },
        userId: Number,
        lessonId: Number,
        startTimestamp: Number,
        markCompleteUrl: String
    },
    data: function(){
        return {
            isComplete : false,
            markCompleteSeconds: 20
        }
    },
    mounted: function(){
        if( this.markComplete !== '' ){
            this.markCompleteSeconds = parseInt( this.markComplete );
        }

        if( this.srcHd !== '' && this.poster !== '' ){
            var _videoJsContainer = $('.video-js-container');

            var _playerIcon = _videoJsContainer.find('.video-play-icon');
            var _videoCaption = _videoJsContainer.find('.video-caption');
            var _image = _videoJsContainer.find('img');

            _playerIcon.find('i').addClass('animated fadeOut').remove();
            _videoCaption.addClass('animated fadeOut');
            _image.addClass('animated fadeOut');

            _videoJsContainer.addClass('played');

            var Player = videojs("player", {
                "controls": true,
                "autoplay": false,
                "preload": "auto" ,
                "poster": this.poster,
                "fluid": true,
                'sources': [
                    {
                        src: this.srcHd,
                        type: 'video/mp4'
                    }
                ],
            });

            if( this.startTimestamp > 0 ){
                Player.currentTime( this.startTimestamp );
            }

            let oldTimestamp = 0;
            let isComplete = this.isComplete;
            Player.on('timeupdate', function(){
                if( Player.currentTime() > ( Player.duration() - this.markCompleteSeconds ) && !isComplete ){
                    //LessonComplete.methods.setComplete(true, false, this.markCompleteUrl );
                    this.$store.state.videoLessonComplete = true;
                    isComplete = true;
                }
                const timestamp = parseInt(Player.currentTime() );

                if( timestamp !== oldTimestamp && this.userId != null ){
                    oldTimestamp = timestamp;
                    if( oldTimestamp%10 === 0 ){
                        let xhrTimestamp = new XMLHttpRequest();
                        xhrTimestamp.open('GET', '/update-lesson-watching/' + this.userId + '/' + this.lessonId + '/' + Player.currentTime() );
                        xhrTimestamp.send();
                    }
                }
            }.bind(this));

            $('[data-timecode]').on('click', function(){
                var _this = $(this);
                if( Player.paused ){
                    Player.play();
                }
                Player.currentTime( _this.data('timecode') );
            });
        }
    }
}
</script>

<style>
</style>