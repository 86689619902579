<template v-html="ajaxInclude"></template>


<script>
export default {
    name: 'AjaxInclude',
    props: [ 'dataUrl' ],
    data: function(){
        return {
            ajaxInclude: null
        }
    },
    mounted: function(){
        this.loadData();
    },
    methods: {
        loadData: function(){
            const xhr = new XMLHttpRequest();
            xhr.open('GET', this.dataUrl);
            xhr.onreadystatechange = function() {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    this.ajaxInclude = xhr.responseText
                }
            }.bind(this);
            xhr.send();

        }

    }
}
</script>

<style>
</style>