import { render } from "./NotificationCenter.vue?vue&type=template&id=1f660392&bindings={\"getNotificationUrl\":\"props\",\"setMarkAsSeenUrl\":\"props\",\"timeoutSeconds\":\"props\",\"updateNotificationCenter\":\"options\",\"readNotification\":\"options\",\"removeNotification\":\"options\",\"updateToasts\":\"options\"}"
import script from "./NotificationCenter.vue?vue&type=script&lang=js"
export * from "./NotificationCenter.vue?vue&type=script&lang=js"

import "./NotificationCenter.vue?vue&type=style&index=0&lang=css"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "1f660392"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1f660392', script)) {
    api.reload('1f660392', script)
  }
  
  module.hot.accept("./NotificationCenter.vue?vue&type=template&id=1f660392&bindings={\"getNotificationUrl\":\"props\",\"setMarkAsSeenUrl\":\"props\",\"timeoutSeconds\":\"props\",\"updateNotificationCenter\":\"options\",\"readNotification\":\"options\",\"removeNotification\":\"options\",\"updateToasts\":\"options\"}", () => {
    api.rerender('1f660392', render)
  })

}

script.__file = "web/vue/NotificationCenter.vue"

export default script