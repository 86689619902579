<template>
    <div class="position-absolute " id="notification-center">
        <div class="notification-center-holder">
            <div v-for="notification in notifications" class="toast ml-auto fade show" role="alert" data-delay="700" data-autohide="false" :data-id="notification.id">
                <div class="toast-header">
                    <small class="mr-auto text-muted">{{ notification.timeString }}</small>
                    <button type="button" class="ml-2 mb-1 close" v-on:click="readNotification(notification.id)" data-dismiss="toast" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="toast-body">{{ notification.message }}</div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'NotificationCenter',
    props: [ 'getNotificationUrl', 'setMarkAsSeenUrl', 'timeoutSeconds' ],
    data: function(){
        return {
            timer: null,
            notifications: []
        }
    },
    mounted: function(){
        this.updateNotificationCenter();
        this.timer = setInterval(this.updateNotificationCenter, (this.timeoutSeconds*1000));
    },
    methods: {
        updateNotificationCenter: function(){
            const xhr = new XMLHttpRequest();
            xhr.open('POST', this.getNotificationUrl);
            const _toasts = $('#notification-center');

            xhr.onreadystatechange = function() {
                if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                    const _content = JSON.parse( xhr.responseText );
                    if( _content.count > 0 ){
                        this.notifications = _content.notifications;
                    }
                }
            }.bind(this);
            xhr.send();

        },
        readNotification: function( notificationId = 0 ){
            console.log('----- READ NOTI -----' + notificationId);
            const xhr = new XMLHttpRequest();
            xhr.open('GET', this.setMarkAsSeenUrl + '?id=' + notificationId );

            const formData = new FormData();
            formData.append('id', notificationId);

            xhr.onreadystatechange = function() {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    const _content = JSON.parse( xhr.responseText );
                    console.log(_content);
                    if( _content.success ){
                        this.removeNotification( notificationId )
                    }
                }
            }.bind(this);

            xhr.send(formData);

        },
        removeNotification: function( notificationId = 0 ){
            console.log('---- REMOVE NOTI ----');
            const notificationArray = [];
            for( const i in this.notifications ){
                const item = this.notifications[i];
                if( notificationId !== item.id ){
                    notificationArray.push(item);
                }
            }
            if( notificationArray.length > 0 ){
                this.notifications = notificationArray;
            }
        },

        updateToasts: function(){

        }

    }
}
</script>

<style>
</style>