import { render } from "./UserNavbar.vue?vue&type=template&id=41f27aa6&bindings={\"dataUrl\":\"props\",\"loadData\":\"options\"}"
import script from "./UserNavbar.vue?vue&type=script&lang=js"
export * from "./UserNavbar.vue?vue&type=script&lang=js"

import "./UserNavbar.vue?vue&type=style&index=0&lang=css"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "41f27aa6"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('41f27aa6', script)) {
    api.reload('41f27aa6', script)
  }
  
  module.hot.accept("./UserNavbar.vue?vue&type=template&id=41f27aa6&bindings={\"dataUrl\":\"props\",\"loadData\":\"options\"}", () => {
    api.rerender('41f27aa6', render)
  })

}

script.__file = "web/vue/UserNavbar.vue"

export default script