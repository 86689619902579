<script>
import NotificationCenter from "./NotificationCenter";
import LessonComplete from "./LessonComplete";
import AjaxInclude from "./AjaxInclude";
import UserNavbar from "./UserNavbar";
import VideoPlayer from "./VideoPlayer";
import CourseCompleteCounter from './CourseCompleteCounter';
import CourseProgress from "./CourseProgress";

export default {
    name: 'vApp',
    components: {
        NotificationCenter,
        AjaxInclude,
        LessonComplete,
        UserNavbar,
        VideoPlayer,
        CourseCompleteCounter,
        CourseProgress,
    }
}
</script>
